
export const CURRENCY =  '€';
export const DFTLNGCODE =  'de';
export const DIGITS = 2;
export const URL = 'https://api.fbsshop.de';
export const IMAGEURL = 'https://ecc.aysales.de';
export const PAYMENTSERVER = 'https://pay.mobiserver.de:4242';
export const VENDORID = '10098002';
export const COMPANYNAME = 'FALKE Bilgi Teknolojileri Ltd. Sti.'
export const VERSION = "Version 2.2.3"
export const PAYMENTSYSTEM = false;
export const BANNER = false;
export const BRANDS = false;
export const FLAG_ITEM_ON_HAND = false;
