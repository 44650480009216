import React, { useState, useContext } from "react";
import { LockClosedIcon } from '@heroicons/react/solid'
import LanguageSelector from '../languages/LanguageSelector';
import GetCustomerCode from "./GetCustomerCode";
import LoginToWebshop from "../components/LoginToWebshop";
import { Text, LanguageContext } from '../languages/Language';
import { ReactComponent as LogoutIcon } from '../assets/pic/logout.svg';
import logo from '../assets/pic/appLogo.png';
import { VERSION } from '../constants';
import fetchItemsFromAPI from "../fetch/fetchItemsFromAPI";
import fetchDataFromAPI from "../fetch/useFetchDataFromAPI";
import ReCAPTCHA from "react-google-recaptcha";

//import ReactDOM from "react-dom";
//import PropTypes from 'prop-types';

function setToken() {
}

function getToken() {
    const tokenString = sessionStorage.getItem('token');
    return tokenString;
}

export default function LoginModal({setShowRegisterModal,onLogoutDeleteItemsAndCategories, onGetCategories, onGetItems, parentHandleToken, children }) {
    const [customerCode, setCustomerCode] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showModall, setshowModall] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [responseText, setResponseText] = useState("");
    const [response, setResponse] = useState(false);

    var token = getToken();

    const switchRegister=()=>{
        setshowModall(false);
        setShowRegisterModal(true);
    }

    const [verified, setVerified] = useState(false);

    const onChange = (value) => {
      setVerified(true);
    };


    function handleLogout() {
        window.location.reload();
        setCustomerCode("")
        setEmail("")
        setPassword("")
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('discRate');
        sessionStorage.removeItem('slipRef');
        sessionStorage.removeItem('items');
        sessionStorage.removeItem('allCategories');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('customerCode');
        sessionStorage.removeItem('Expl1');
        sessionStorage.removeItem('Expl2');
        //localStorage.clear();
        setToken("");
        setIsSubmitted(false);
        parentHandleToken(false);
        onLogoutDeleteItemsAndCategories()
    }

    const handleSubmit = async event => {
        event.preventDefault();

        if (!verified) {
            alert("Please verify that you are not a robot.");
            return;
          }

        sessionStorage.setItem('email', email)
        sessionStorage.setItem('customerCode', customerCode)
        LoginToWebshop(customerCode, email, password)
            .then((json) => {
                if (json.resCode == 200) {
                    fetchDataFromAPI(1306) //service for categories
                        .then(() => {
                            onGetCategories()
                            fetchItemsFromAPI(json.dataSet[0]?.token) //service for GetBasket AND! service for Items.Get.Customer.Catalog
                                .then(() => {
                                    onGetItems()
                                })
                        });
                        token = getToken();
                        if (token) {
                            setIsSubmitted(true);
                            parentHandleToken(true);
                        }
                }else{
                    setResponseText(json.resMessage);
                    setResponse(true);
                }

            })
    };

    const renderForm = (
        <>
            <button
                className=" items-center mx-0
                justify-center pt-1
                text-base font-bold  hover:opacity-75"
                type="button"
                onClick={() => setshowModall(true)}
            >
                {children ? children :
                    <Text tid="Login" />}
            </button>
            {showModall ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden
                        overflow-y-auto fixed inset-1 sm:inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div
                            className="relative text-slate-900 w-auto"
                        >
                            {/*content*/}

                            <div
                                className=" border-0 rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                            >
                                {/*header*/}
                                <div
                                    className="flex items-center justify-between px-10 pt-4 sm:px-4 sm:pt-2 border-b border-solid border-transparent rounded-t"
                                >
                                    <button
                                        className="flex items-center justify-center border-0 text-black opacity-50 float-right text-xl
                                        leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setshowModall(false)}
                                    >
                                        <span
                                            className="bg-transparent h-7 w-7 text-xl inline-block align-text-top text-company-primary-color hover:text-company-primary-color-100"
                                        >
                                            &#10006;
                                        </span>
                                    </button>
                                    <h3
                                        className="text-lg w-auto font-semibold text-company-primary-color"
                                    >
                                        <Text tid="SignIn" />
                                    </h3>

                                </div>
                                {/*body*/}
                                <div
                                    className="relative flex-auto p-4 "
                                >
                                    <div
                                        className="pb-4 text-slate-500 text-lg leading-relaxed ">
                                        { /*----------------------------------------------------------------------------*/}

                                        <div className="h-auto flex flex-row items-center justify-center py-1 px-1 sm:px-1 lg:px-1">

                                            <div className="flex flex-col self-center space-y-1 justify-center items-center">
                                                <div className="w-64 justify-center items-center">
                                                <div className="justify-center items-center rounded-md">
                                                   <center><img className="" src={logo} alt="Logo"/> </center>
                                                </div>
                                                </div>

                                                <form
                                                    className="mt-1 space-y-6 "
                                                    action="#"
                                                    method="POST"
                                                    onSubmit={handleSubmit}>
                                                    <input
                                                        type="hidden"
                                                        name="remember"
                                                        defaultValue="true"
                                                    />
                                                    <div
                                                        className="text-sm px-4"
                                                    >


                                                        <div className="p-5 bg-white rounded-md gap-2 shadow-md border border-gray-300 ">

                                                                <div className=" py-1">
                                                                <label className="mt-2 "><Text tid="EmailAddress"/></label>
                                                                <input id="email-address" name="email" type="email" autoComplete="email" required
                                                                        className="w-full text-sm p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0"
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        />
                                                                </div>

                                                                <div className=" py-1">
                                                                <label className="mt-2"><Text tid="Password"/></label>
                                                                <input id="pass" name="pass" type="password" autoComplete="current-password"
                                                                className="w-full text-sm p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0"
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                required/>
                                                                </div>


                                                                <div className="mt-2">
                                                                <label ><Text tid="CustomerCode"/></label>
                                                                <div className="px-0.5 bg-gray-50 rounded-md  border border-gray-300">
                                                                <div className="bg-gray-50 focus:z-10 flex flex-row items-center" >
                                                                <input
                                                                    onChange={(e) => setCustomerCode(e.target.value)}
                                                                    value={customerCode}
                                                                    id="customerCode"
                                                                    name="customerCode"
                                                                    type="text"
                                                                    autoComplete="customerCode"
                                                                    required
                                                                    className="text-sm appearance-none h-8 rounded-none relative block w-full border-none bg-gray-50 focus:outline-none focus:z-10"
                                                                />
                                                                <div className="divide-none">
                                                                <GetCustomerCode
                                                                    email={email}
                                                                    password={password}
                                                                    getCustomerCode={(code) => setCustomerCode(code)}
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>

                                                                <div className="py-1 pt-4 flex justify-center">
                                                                <ReCAPTCHA
                                                                sitekey="6LfBMQsqAAAAAAcQ8BZ0gAgZ3Jaexg8WUf6KzCAH"
                                                                onChange={onChange}
                                                                    />
                                                                        </div>
                                                        </div>


                                                    </div>
                                                    <div className="flex px-4 w-full items-center justify-center">
                                                            <button
                                                            type="submit"
                                                            className="border-y-2 border-x-2 rounded-md  border-company-primary-color group relative w-full flex justify-center
                                                            font-medium bg-transparent hover:bg-company-primary-color focus:outline-none focus:ring-2
                                                            focus:ring-offset-2 text-company-primary-color hover:text-white focus:ring-company-primary-color items-center px-1 py-1 text-base shadow-sm hover:opacity-75"                                                        >
                                                            <span
                                                                className="absolute left-0 inset-y-0 flex items-center pl-3"
                                                            >
                                                                <LockClosedIcon
                                                                    className="h-5 w-5 text-company-primary-color group-hover:text-red-100"
                                                                    aria-hidden="true" />
                                                            </span>
                                                            <Text tid="SignIn" />
                                                        </button>

                                                    </div>
                                                    <div className="flex flex-row justify-between px-4 sm:px-0 h-2">
                                                    <div className="text-company-primary-color text-xs text-center mt-1">
                                                        {VERSION}
                                                    </div>
                                                    {

                                                        <button onClick={() => switchRegister()}
                                                    className=" text-company-primary-color text-sm font-bold text-center hover:text-company-primary-color-100"><Text tid="Register" />
                                                    </button>

                                                    }

                                                    </div>

                                                </form>
                                            </div>

                                        </div>
                                        {/*-----------------------------------------------------------------------------------------*/}
                                    </div>
                                </div>
                                {/*footer*/}

                            </div>
                        </div>
                    </div>
                    <div
                        className="opacity-50 fixed inset-0 z-40 bg-black"
                    >
                    </div>
                </>
            ) : null}

{response?
          <>
          <div
              className="justify-center items-center flex overflow-x-hidden
overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
              <div
                  className="relative w-auto my-6 mx-auto max-w-3xl"
              >
                  {/*content*/}
                  <div
                      className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                      {/*header*/}
                      <div
                                className=" flex items-start text-center justify-center p-5 border-b border-solid border-slate-200 rounded-t"
                            >
                                <h3
                                    className="text-lg font-semibold text-center text-black"
                                >
                                  <Text tid="Error"></Text>
                                </h3>

                            </div>
                      {/*body*/}
                      <div
                          className="min-w-[15rem] relative p-6 flex-auto"
                      >
                          <div
                              className="text-center text-black">
                              {responseText}
                              <div className='flex flex-row justify-center mt-6'>
                                  <button
                                  onClick={() => setResponse(false)}
                                      className="flex min-w-[8rem] items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                                      type="button"

                                  >
                                      <Text tid="Ok" />
                                  </button>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div
              className="opacity-50 fixed inset-0 z-40 bg-black"
          >
          </div>
      </>
        :null}
        </>
    );

    const renderLogout = (
        <button
            className="flex w-28 lg:w-32 items-center
            rounded-md border border-transparent
            text-sm shadow-sm hover:opacity-75"
            type="button"
            onClick={() => handleLogout()}
        >
            <LogoutIcon className="w-6 h-6 fill-company-primary-color mx-1" />
            <Text tid="SignOff" />
        </button>
    );

    return (
        <div>
            {
                isSubmitted
                    ? renderLogout
                    : sessionStorage.getItem('token')
                        ? renderLogout
                        : renderForm
            }
        </div>
    );
}

